<template>
  <div
    class="goodsV1"
    v-debounce="[
      'click',
      () => {
        onClick();
      },
    ]"
  >
    <div style="position:relative">
      <img :src="url" />
      <!--<div class="mask" v-if="stock_number==0">
        <div class="mask-big-circle">
          <div class="mask-small-circle">
            {{tipText}}
          </div>
        </div>
      </div>-->
    </div>

    <div class="text-ellipsis1">{{ title }}</div>
    <div>
      <span>¥{{ product_price }}</span>
      <span>¥{{ market_price }}</span>
    </div>
    <img
      v-if="mail_type == 1 && stock_number>0"
      :src="require('@STATIC/image/icons/ziti.png')"
      class="goodsV1_tip"
    />
    <img
            v-if="stock_number==0"
            :src="require('@STATIC/image/icons/zero.png')"
            class="goodsV1_zero"
    />
  </div>
</template>

<script>
export default {
  name: "goodsV1",
  props: {
    //1是快递 2是自提
    mail_type: {
      type: [Number, String],
      default: "",
    },
    stock_number: {
      type: [Number, String],
      default: 1,
    },
    url: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    tipText: {
      type: String,
      default: "补货中",
    },
    product_price: {
      type: [String, Number],
      default: "",
    },
    market_price: {
      type: [String, Number],
      default: "",
    },
    param: null,
  },
  methods: {
    onClick() {
      this.$emit("onClick", this.$props.param);
    },
  },

};
</script>

<style lang="scss" scoped>
@include b(goodsV1) {
  position: relative;
  box-sizing: border-box;
  width: 168px;
  // height: 109px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 0.5px 3.5px 1px rgba(115, 115, 115, 0.05);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  &> div > img {
    width: 148px;
    height: 148px;
    border-radius: 3px;
  }
  @include e(tip) {
    position: absolute;
    width: 39px !important;
    height: 39px !important;
    border-radius: 0 !important;
    right: 0;
    top: 0;
  }
  & > div {

    &:last-of-type {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > span:first-of-type {
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #939393;
        text-decoration: line-through;
      }
      & > span:last-of-type {
        margin-left: 6px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #c10000;
      }
    }
  }
  .text-ellipsis1 {
    margin-top: 8px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .mask{
    background: #EDEDED;
    border-radius: 8px;
    opacity: 0.5;
    border:0px solid red;
    position:absolute;
    z-index:2;
    width:100%;
    text-align:center;
    left:0px;
    top: 0px;
    height:100%;
    display: flex;

  }
  .mask-big-circle{
    margin: auto;
    width: 85px;
    height: 85px;
    border-radius:50%;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
  }
  .mask-small-circle{
    width: 73px;
    height: 73px;
    border: 2px solid #EDEDED;
    border-radius:50%;
    margin: auto;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 63px;
    text-align: center;
  }

  .goodsV1_zero{
    position: absolute;
    width: 56px !important;
    height: 45px !important;
    border-radius: 0 !important;
    right: 0;
    top: 0;
  }
}
</style>
